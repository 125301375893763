import {
  Button,
  ButtonColor,
  ButtonWidth,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import React, { type ReactElement } from 'react';

type Props = (
  | { primary: true; danger?: never }
  | { primary?: never; danger: true }
) & {
  text: string | ReactElement;
  link: {
    href: string;
    label: string;
    external?: boolean;
  };
};

// TODO zando check this

const SidebarAlert: React.FC<Props> = ({
  primary,
  // danger, == !primary
  text,
  link: { href, label, external },
}) => {
  const [bgClassName, textClassName, buttonColor] = primary
    ? ['bg-leland-dark-green', 'text-white font-normal', ButtonColor.PRIMARY]
    : ['bg-leland-red', 'text-white font-medium', ButtonColor.WHITE];

  const button = (
    <Button label={label} buttonColor={buttonColor} width={ButtonWidth.FULL} />
  );

  return (
    <div
      className={`${bgClassName} m-1 mb-4 flex flex-col items-center rounded-2xl p-3`}
    >
      <p className={`${textClassName} text-center text-base leading-snug`}>
        {text}
      </p>
      {external ? (
        <a className="mt-3 w-full" href={href} target="_blank" rel="noreferrer">
          {button}
        </a>
      ) : (
        <Link href={href} className="mt-3 w-full">
          {button}
        </Link>
      )}
    </div>
  );
};

export default SidebarAlert;
