import React, { type PropsWithChildren } from 'react';

import PageHead, { type PageHeadProps } from './PageHead';

type PageProps = PropsWithChildren<PageHeadProps>;

const Page: React.FC<PageProps> = ({ children, ...headProps }) => {
  return (
    <>
      <PageHead {...headProps} />
      {children}
    </>
  );
};

export default Page;
