import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@leland-dev/leland-ui-library';
import React, { type ReactElement, type SVGProps } from 'react';

interface SidebarNoticeProps {
  text: string | ReactElement;
  buttonLabel: string;
  leftIcon?: React.FC<SVGProps<SVGSVGElement>>;
  onClick: () => void;
}

const SidebarNotice: React.FC<SidebarNoticeProps> = ({
  text,
  buttonLabel,
  leftIcon,
  onClick,
}) => {
  return (
    <div className="m-1 mb-4 flex flex-col items-start rounded-lg border border-leland-gray-stroke p-3 shadow-sm">
      <p className="text-leland-gray mb-2 space-y-1 text-start text-base leading-snug">
        {text}
      </p>
      <Button
        label={buttonLabel}
        LeftIcon={leftIcon}
        buttonColor={ButtonColor.WHITE}
        width={ButtonWidth.FULL}
        size={ButtonSize.SMALL}
        onClick={onClick}
      />
    </div>
  );
};

export default SidebarNotice;
