// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoachingSessionAlertBannerClassFragment = {
  __typename?: 'Class';
  id: string;
  name?: string | null;
  isFreeEvent?: boolean | null;
};

export type CoachingSessionAlertBannerOneOnOneSessionFragment = {
  __typename?: 'OneOnOneSession';
  title: string;
};

export type CoachingSessionAlertBannerCoachingSessionFragment = {
  __typename?: 'CoachingSession';
  id: string;
  startAt: number;
  endAt: number;
  content:
    | {
        __typename?: 'Class';
        id: string;
        name?: string | null;
        isFreeEvent?: boolean | null;
      }
    | { __typename?: 'OneOnOneSession'; title: string };
  attendees?: Array<{
    __typename?: 'Applicant';
    id: string;
    user: { __typename?: 'User'; id: string; firstName: string };
  }> | null;
};

export type CoachingSessionAlertBannerUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CoachingSessionAlertBannerUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        attendees?: Array<{
          __typename?: 'Applicant';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        }> | null;
      }> | null;
    } | null;
  } | null;
};

export const CoachingSessionAlertBannerClassFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_Class on Class {
    id
    name
    isFreeEvent
  }
`;
export const CoachingSessionAlertBannerOneOnOneSessionFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_OneOnOneSession on OneOnOneSession {
    title
  }
`;
export const CoachingSessionAlertBannerCoachingSessionFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_CoachingSession on CoachingSession {
    id
    startAt
    endAt
    content {
      ...CoachingSessionAlertBanner_Class
      ...CoachingSessionAlertBanner_OneOnOneSession
    }
    attendees(count: 1) {
      id
      user {
        id
        firstName
      }
    }
  }
  ${CoachingSessionAlertBannerClassFragmentDoc}
  ${CoachingSessionAlertBannerOneOnOneSessionFragmentDoc}
`;
export const CoachingSessionAlertBannerUserDocument = gql`
  query CoachingSessionAlertBanner_User {
    user {
      id
      coach {
        id
        upcomingEnrolledSessions {
          ...CoachingSessionAlertBanner_CoachingSession
        }
      }
    }
  }
  ${CoachingSessionAlertBannerCoachingSessionFragmentDoc}
`;

/**
 * __useCoachingSessionAlertBannerUserQuery__
 *
 * To run a query within a React component, call `useCoachingSessionAlertBannerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingSessionAlertBannerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingSessionAlertBannerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingSessionAlertBannerUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >(CoachingSessionAlertBannerUserDocument, options);
}
export function useCoachingSessionAlertBannerUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >(CoachingSessionAlertBannerUserDocument, options);
}
export function useCoachingSessionAlertBannerUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachingSessionAlertBannerUserQuery,
    CoachingSessionAlertBannerUserQueryVariables
  >(CoachingSessionAlertBannerUserDocument, options);
}
export type CoachingSessionAlertBannerUserQueryHookResult = ReturnType<
  typeof useCoachingSessionAlertBannerUserQuery
>;
export type CoachingSessionAlertBannerUserLazyQueryHookResult = ReturnType<
  typeof useCoachingSessionAlertBannerUserLazyQuery
>;
export type CoachingSessionAlertBannerUserSuspenseQueryHookResult = ReturnType<
  typeof useCoachingSessionAlertBannerUserSuspenseQuery
>;
export type CoachingSessionAlertBannerUserQueryResult = Apollo.QueryResult<
  CoachingSessionAlertBannerUserQuery,
  CoachingSessionAlertBannerUserQueryVariables
>;
