import {
  BrandSlack,
  Breakpoints,
  compact,
  type HeaderEntry,
  HeaderEntryTextColor,
  IconCalendar,
  IconDiscount,
  IconEarnings,
  IconGraduateHat,
  IconInbox,
  IconLightning,
  IconOpsTools,
  IconProfile,
  IconQuestion,
  IconSettings,
  IconSignOut,
  IconSparkle,
  IconStarOutline,
  IconStore,
  IconSwitch,
  IconWarningTriangle,
  type NavProps,
  NavTheme,
  PageHeader,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import React, { type ReactElement, useMemo } from 'react';

import { CoachStatus } from '../__generated-gql-types__/globalTypes';
import { useAuth, useLogout } from '../context/AuthContext';
import { LexSlug, useLex } from '../context/LexContext';
import { useOnboardingStatuses } from '../context/OnboardingStatusesContext';
import {
  APPLICANT_SITE_URL,
  APPROVED_COACH_STATUSES,
  BASE_RELEASE_NOTES_URL,
  COACH_COMMUNITY_SLACK_URL,
  COACH_LIBRARY_URL,
  ONBOARDED_COACH_STATUSES,
  SUPPORT_URL,
} from '../utils/constants';
import { LelandImpersonation } from '../utils/impersonation';

import IncompleteIndicator from './common/IncompleteIndicator';
import NewIndicator from './common/NewIndicator';

interface NavLink {
  href: string;
  label: string;
  icon?: ReactElement;
  isExternal?: boolean;
}

interface NavbarProps {
  // hide navigation sidebar and profile menu navigation options
  hideInternalNavigation?: boolean;
  navLinks?: NavLink[];
}

const Navbar: React.FC<NavbarProps> = ({ hideInternalNavigation }) => {
  const { currentUser, isLoadingUser, isImpersonating } = useAuth();
  const isSm = useMediaQuery(Breakpoints.SM);
  const isMd = useMediaQuery(Breakpoints.MD);

  const { isLexEnabled } = useLex();

  const { statuses } = useOnboardingStatuses();
  const { logout, logoutLoading } = useLogout();

  const isApplicant = currentUser?.applicant != null;

  const approvedCoach =
    currentUser?.coach?.status != null &&
    APPROVED_COACH_STATUSES.includes(currentUser?.coach?.status);

  const showSettingsIncompleteIndicator =
    currentUser?.coach?.status === CoachStatus.APPROVED &&
    !statuses?.stripeDone;

  const isOnboardedCoach =
    currentUser?.coach?.status != null &&
    ONBOARDED_COACH_STATUSES.includes(currentUser?.coach?.status);

  const isApprovedCoach =
    currentUser?.coach?.status != null &&
    (isOnboardedCoach ||
      APPROVED_COACH_STATUSES.includes(currentUser.coach.status));

  const IMPERSONATION_HEADER_ENTRY: HeaderEntry = useMemo(
    () => ({
      label: 'EXIT IMPERSONATION',
      icon: IconWarningTriangle,
      textColor: HeaderEntryTextColor.RED,
      onClick: () => {
        LelandImpersonation.removeImpersonatedUserId();
        window.location.href =
          '/internal/ops/coaches?status=' + currentUser?.coach?.status;
      },
    }),
    [currentUser?.coach?.status],
  );

  const mobileSidebarLinks: HeaderEntry[] = useMemo(() => {
    const approvedCoachOptions = isApprovedCoach
      ? [
          {
            url: '/calendar/appointments/upcoming',
            icon: IconCalendar,
            label: 'Calendar',
            rightIcon: showSettingsIncompleteIndicator ? (
              <IncompleteIndicator />
            ) : undefined,
          },
          {
            icon: IconStore,
            label: 'Offerings',
            rightIcon: showSettingsIncompleteIndicator ? (
              <IncompleteIndicator />
            ) : undefined,
            childEntries: [
              {
                label: 'Hourly',
                url: '/offerings/hourly',
              },
              {
                label: 'Packages',
                url: '/offerings/packages',
              },
              ...(isLexEnabled(LexSlug.FEATURE_COACH_FREE_EVENTS)
                ? [
                    {
                      label: 'Free events',
                      url: '/offerings/events',
                    },
                  ]
                : []),
              ...(isLexEnabled(LexSlug.FEATURE_COACH_FREE_EVENTS) &&
              !currentUser?.coach?.allowPaidClass
                ? []
                : [
                    {
                      label: 'Group classes',
                      url: '/offerings/classes',
                    },
                  ]),
              {
                label: 'Leland+',
                url: '/offerings/leland-plus',
              },
            ],
          },
          {
            url: '/earnings',
            icon: IconEarnings,
            label: 'Earnings',
            rightIcon: showSettingsIncompleteIndicator ? (
              <IncompleteIndicator />
            ) : undefined,
          },
        ]
      : [];
    const onboardedCoachOptions = isOnboardedCoach
      ? [
          isLexEnabled(LexSlug.FEATURE_COACH_OPPORTUNITIES)
            ? {
                url: '/opportunities',
                icon: IconLightning,
                label: 'Opportunities',
                rightIcon: <NewIndicator />,
              }
            : null,
          {
            url: '/reviews',
            icon: IconStarOutline,
            label: 'Reviews',
          },
          {
            url: '/discount-codes',
            icon: IconDiscount,
            label: 'Discount Codes',
          },
        ]
      : [];
    const opsOption = currentUser?.isOps
      ? {
          url: '/internal/ops/coaches',
          icon: IconOpsTools,
          label: 'Ops Tools',
        }
      : null;
    return [
      isImpersonating ? IMPERSONATION_HEADER_ENTRY : null,
      {
        url: '/inbox',
        icon: IconInbox,
        label: 'Inbox',
      },
      {
        url: '/profile/background',
        isExternal: false,
        icon: IconProfile,
        label: 'Profile',
      },
      ...approvedCoachOptions,
      ...onboardedCoachOptions,
      opsOption,
    ].filter(compact);
  }, [
    IMPERSONATION_HEADER_ENTRY,
    currentUser?.coach?.allowPaidClass,
    currentUser?.isOps,
    isApprovedCoach,
    isImpersonating,
    isLexEnabled,
    isOnboardedCoach,
    showSettingsIncompleteIndicator,
  ]);

  const leftLinks = useMemo(() => {
    const links: NavProps['leftLinks'] = [];

    if (isImpersonating && currentUser && isSm) {
      links.push(IMPERSONATION_HEADER_ENTRY);
    }

    return links.length ? links : undefined;
  }, [IMPERSONATION_HEADER_ENTRY, currentUser, isImpersonating, isSm]);

  const profileMenuItemSections: HeaderEntry[][] = useMemo(() => {
    const applicantOptions: HeaderEntry[] =
      isApplicant || !hideInternalNavigation
        ? [
            {
              url: '/settings',
              icon: IconSettings,
              label: 'Settings',
            },
          ]
        : [];
    const approvedCoachProfileMenuOptions = approvedCoach
      ? [
          {
            url: COACH_COMMUNITY_SLACK_URL,
            isExternal: true,
            icon: BrandSlack,
            label: 'Coach Community',
          },
          {
            url: COACH_LIBRARY_URL,
            icon: IconGraduateHat,
            label: "Coach's Corner",
          },
        ]
      : [];

    const switchToClientOption =
      isApplicant || !hideInternalNavigation
        ? {
            url: APPLICANT_SITE_URL,
            icon: IconSwitch,
            label: 'Switch to Client view',
          }
        : null;

    const topSection: HeaderEntry[] = applicantOptions;
    const sections: Array<Nullable<Array<Nullable<HeaderEntry>>>> = [
      ...(isImpersonating ? [[IMPERSONATION_HEADER_ENTRY]] : []),

      topSection,
      [
        switchToClientOption,
        ...approvedCoachProfileMenuOptions,
        {
          url: SUPPORT_URL,
          isExternal: true,
          icon: IconQuestion,
          label: 'Help & Support',
        },
        {
          onClick: (e) => {
            e.preventDefault();
            void logout();
          },
          icon: IconSignOut,
          label: 'Log Out',
        },
      ],
    ];

    return sections.reduce<HeaderEntry[][]>(
      (reducedSections, currentSection) => {
        const filteredCurrentSection = currentSection?.filter(compact);
        if (filteredCurrentSection?.length) {
          reducedSections.push(filteredCurrentSection);
        }
        return reducedSections;
      },
      [],
    );
  }, [
    IMPERSONATION_HEADER_ENTRY,
    approvedCoach,
    hideInternalNavigation,
    isApplicant,
    isImpersonating,
    logout,
  ]);

  const rightLinks: NavProps['rightLinks'] = useMemo(() => {
    const links: NavProps['rightLinks'] = [];
    if (currentUser && !isMd) {
      links.push({
        label: 'Inbox',
        icon: IconInbox,
        hideLabel: true,
        url: `/inbox`,
      });
    }
    return links;
  }, [currentUser, isMd]);

  const LulNavProps: NavProps = {
    theme: isImpersonating ? NavTheme.RED : undefined,
    mobileLinks: hideInternalNavigation
      ? undefined
      : {
          sidebar: mobileSidebarLinks,
          footer: [
            {
              label: "What's New",
              url: BASE_RELEASE_NOTES_URL,
              isExternal: true,
              icon: IconSparkle,
            },
          ],
        },
    leftLinks,
    rightLinks,
    urls: {
      home: '/',
      login: `/login`,
      signup: `/signup`,
      inbox: `/inbox`,
    },
    user: currentUser
      ? {
          ...currentUser,
          profileLink: '/profile/background',
        }
      : undefined,
    profileMenuItemSections: profileMenuItemSections,
    userLoading: isLoadingUser || logoutLoading,
  };

  return <PageHeader sticky={true} navProps={LulNavProps} subNavProps={null} />;
};

export default Navbar;
