import { IconWarning, Tooltip } from '@leland-dev/leland-ui-library';
import React from 'react';

const DEFAULT_ONBOARDING_MESSAGE =
  'This section needs to be completed before submitting for approval';
const DEFAULT_INCOMPLETE_MESSAGE = 'This section is incomplete';

type Props = Pick<React.ComponentPropsWithoutRef<typeof Tooltip>, 'side'> & {
  className?: string;
  onboarding?: boolean;
  message?: string;
};

const IncompleteIndicator: React.FC<Props> = ({
  className,
  onboarding,
  message = onboarding
    ? DEFAULT_ONBOARDING_MESSAGE
    : DEFAULT_INCOMPLETE_MESSAGE,
  ...tooltipProps
}) => (
  <Tooltip content={message} {...tooltipProps}>
    <IconWarning className={`size-5 text-leland-red ${className}`} />
  </Tooltip>
);

export default IncompleteIndicator;
