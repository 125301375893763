import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';

const MessageCountHead = dynamic(() => import('./MessageCountHead'), {
  ssr: false,
});

const formatTitle = (title: string): string => {
  return title ? `${title} | Leland` : 'Leland';
};

export interface PageHeadProps {
  title: string;
  canonicalLink?: string;
  noIndex?: boolean;
}

const PageHead: React.FC<PageHeadProps> = ({
  title: titleProp,
  canonicalLink,
  noIndex,
}) => {
  const title = formatTitle(titleProp);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title key="title">{title}</title>
        {canonicalLink ? <link rel="canonical" href={canonicalLink} /> : null}
        {noIndex ? <meta name="robots" content="noindex" /> : null}
      </Head>
      <MessageCountHead title={title} />
    </>
  );
};

export default PageHead;
