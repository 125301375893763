import {
  type Class,
  type CoachingSession,
  type OneOnOneSession,
  type Scalars,
} from '../__generated-gql-types__/globalTypes';

export interface DefaultScheduleSessionType {
  id?: string | null;
  classId?: Scalars['ClassUrn']['output'] | null;
  date?: CoachingSession['startAt'] | null;
  time?: CoachingSession['startAt'] | null;
}

export const DEFAULT_SCHEDULE_SESSION_VALUES: DefaultScheduleSessionType = {
  id: null,
  classId: null,
  date: null,
  time: null,
};

type RawSessionType = Omit<DefaultScheduleSessionType, 'classId'> & {
  classInfo: {
    id: string;
  };
};

export const formatEditSessionValues = (
  rawValues: RawSessionType,
): DefaultScheduleSessionType => {
  return {
    id: rawValues.id ?? null,
    classId: rawValues.classInfo.id ?? null,
    date: rawValues.date ?? null,
    time: rawValues.date ?? null,
  };
};

export const isSessionOneOnOne = <
  C extends Pick<Class, '__typename'>,
  O extends Pick<OneOnOneSession, '__typename'>,
>(
  session: C | O | undefined,
): session is O => {
  return session?.__typename === 'OneOnOneSession';
};
