import {
  type DateType,
  DAY,
  formatTime,
  HOUR,
  MINUTE,
} from '@leland-dev/leland-ui-library';

export const formatTimeRange = (startTime: DateType, endTime: DateType) => {
  return `${formatTime(startTime)} \u2013 ${formatTime(endTime)}`;
};

export const formatTimeUntil = (time: number) => {
  if (time < 0) {
    return '0d 0h 0m';
  }

  let remaining = time;
  const days = Math.floor(remaining / DAY);
  remaining -= days * DAY;
  const hours = Math.floor(remaining / HOUR);
  remaining -= hours * HOUR;
  const minutes = Math.floor(remaining / MINUTE);

  const totalHours = time / HOUR;

  return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${
    minutes && totalHours < 24 ? `${minutes}m` : ''
  }`;
};

export const normalizeDateTime = (
  date?: Nullable<number>,
  time?: Nullable<number>,
): number => {
  const cleanedDate = new Date(date ?? Date.now());
  cleanedDate.setHours(new Date(time ?? Date.now()).getHours());
  cleanedDate.setMinutes(new Date(time ?? Date.now()).getMinutes());
  cleanedDate.setSeconds(0);
  cleanedDate.setMilliseconds(0);

  return cleanedDate.getTime() ?? Date.now();
};
