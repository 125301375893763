import { type FC, type ReactNode } from 'react';

interface NotificationIconProps {
  icon: ReactNode;
  notificationCount: Possible<number>;
}

export const NotificationIcon: FC<NotificationIconProps> = ({
  icon,
  notificationCount,
}) => {
  return (
    <div className="relative">
      {icon}
      {notificationCount ? (
        <div className="absolute -right-2.5 top-0 flex size-5 items-center justify-center rounded-full border-2 border-white bg-leland-red text-xs font-semibold text-white">
          {notificationCount}
        </div>
      ) : null}
    </div>
  );
};
