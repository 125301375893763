/**
 * Util to test slow async behavior
 * @param ms milliseconds to delay
 */
export const delay = (ms = 2000): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const areSetsEqual = <TSetMember>(
  as: Set<TSetMember>,
  bs: Set<TSetMember>,
): boolean => {
  if (as.size !== bs.size) return false;
  for (const a of as) if (!bs.has(a)) return false;
  return true;
};

export type StringOrStringFetcher = string | (() => Promise<string>);

/**
 * Safari requires `navigator.clipboard.write` to be called as a direct response
 * to user action (e.g. click event), meaning it will be blocked if we call it
 * in a promise chain.
 *
 * Instead, we  use the ClipboardItem API to write to the clipboard and invert
 * the promise chain.
 *
 * If ClipboardItem is not available (e.g. in Firefox), we can fallback to
 * `navigator.clipboard.writeText`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard
 *
 * If all of that fails, we can fallback to creating an input element and
 * using document.execCommand('copy') to copy the text to the clipboard.
 *
 * @param textOrFetchText Either the text to copy, or a function that returns a
 * promise of the text to copy
 * @returns Promise that resolves to true if the text was copied successfully
 */
export const copyToClipboard = async (
  textOrFetchText: StringOrStringFetcher,
): Promise<boolean> => {
  const textPromise =
    typeof textOrFetchText === 'string'
      ? Promise.resolve(textOrFetchText)
      : textOrFetchText();

  try {
    if (typeof ClipboardItem && navigator.clipboard.write) {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': textPromise.then(
            (text) => new Blob([text], { type: 'text/plain' }),
          ),
        }),
      ]);
      return true;
    } else if (navigator.clipboard.writeText) {
      const text = await textPromise;
      await navigator.clipboard.writeText(text);
      return true;
    }
  } catch (e) {
    console.warn(e);
  }

  const input = document.createElement('input');
  input.style.display = 'none';
  document.body.appendChild(input);
  input.value = await textPromise;
  input.focus();
  input.select();
  const result = document.execCommand('copy');
  input.remove();

  return !!result;
};

/**
 * Util to check whether all values in a dictionary are undefined
 */
export const areAllValuesUndefined = <T>(obj: Record<string, T>): boolean => {
  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined
    ) {
      return false;
    }
  }
  return true;
};

export const valueAsNumberEmptyIsZero = (value: string) => {
  if (value === '') {
    return 0;
  }
  return parseInt(value);
};

export const HOUR_MINUTE_INPUT_REGISTER_OPTIONS = Object.freeze({
  TIME_IN_MINUTE: {
    setValueAs: valueAsNumberEmptyIsZero,
    min: 0,
    max: 100 * 60,
  },
});
