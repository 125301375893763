import {
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import { useCallback } from 'react';

import { copyToClipboard, type StringOrStringFetcher } from '../common';

const useCopyToClipboardWithToast = (): ((
  text: StringOrStringFetcher,
  entity?: string,
) => Promise<void>) => {
  const { showToast } = useToast();
  return useCallback(
    async (text: StringOrStringFetcher, entity?: string) => {
      if (await copyToClipboard(text)) {
        showToast({
          type: ToastType.SUCCESS,
          duration: ToastDuration.SHORT,
          message: `Copied${entity ? ` ${entity}` : ''} to clipboard.`,
        });
      } else {
        showToast({
          type: ToastType.ERROR,
          duration: ToastDuration.SHORT,
          message: `Failed to copy${entity ? ` ${entity}` : ''} to clipboard`,
        });
      }
    },
    [showToast],
  );
};

export default useCopyToClipboardWithToast;
