import React from 'react';

interface NewIndicatorProps {
  className?: string;
}

const NewIndicator: React.FC<NewIndicatorProps> = ({ className = '' }) => {
  return (
    <div
      className={`rounded bg-leland-red px-1.5 py-1 text-2.5 font-medium leading-none text-white ${className}`}
    >
      NEW
    </div>
  );
};

export default NewIndicator;
