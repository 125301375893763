import {
  getTimeDifference,
  MINUTE,
  TimeUnit,
} from '@leland-dev/leland-ui-library';
import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';

import type { Scalars } from '../__generated-gql-types__/globalTypes';

import { formatTimeUntil } from './datetime';

export enum CoachingSessionUrgencyEnum {
  NOW,
  SOON,
  NOT_SO_SOON,
}

const formatRemainingTime = (currentDate: number, startDate: number) =>
  formatTimeUntil(getTimeDifference(currentDate, startDate));

const checkIfUrgent = (
  currentDate: number,
  startDate: number,
): CoachingSessionUrgencyEnum => {
  const timeDifference = getTimeDifference(
    currentDate,
    startDate,
    TimeUnit.MINUTE,
  );
  if (timeDifference <= 0) {
    return CoachingSessionUrgencyEnum.NOW;
  } else if (timeDifference <= 15) {
    return CoachingSessionUrgencyEnum.SOON;
  }
  return CoachingSessionUrgencyEnum.NOT_SO_SOON;
};

const checkIsStarted = (currentDate: number, startDate: number): boolean =>
  getTimeDifference(currentDate, startDate) < 0;

const checkIsPast = (
  currentDate: number,
  startDate: number,
  endAt?: number,
): boolean => getTimeDifference(currentDate, endAt ? endAt : startDate) < 0;

interface CoachingSessionTimeRemainingValues {
  urgency: CoachingSessionUrgencyEnum;
  remainingTime: string;
  isStarted: boolean;
  isPast: boolean;
}

export const useCoachingSessionTimeRemaining = (
  startDate: Possible<number>,
  endAt?: number,
): CoachingSessionTimeRemainingValues => {
  const [values, setValues] = useState<CoachingSessionTimeRemainingValues>(
    () => {
      const now = Date.now();
      return {
        urgency: startDate
          ? checkIfUrgent(now, startDate)
          : CoachingSessionUrgencyEnum.NOT_SO_SOON,
        remainingTime: startDate ? formatRemainingTime(now, startDate) : '',
        isStarted: startDate ? checkIsStarted(now, startDate) : false,
        isPast: startDate ? checkIsPast(now, startDate, endAt) : false,
      };
    },
  );

  useEffect(() => {
    if (!values.isPast && startDate) {
      const runUpdate = () => {
        const currentTimestamp = Date.now();

        setValues((prev) => {
          const newValues = {
            urgency: checkIfUrgent(currentTimestamp, startDate),
            isStarted: checkIsStarted(currentTimestamp, startDate),
            isPast: checkIsPast(currentTimestamp, startDate, endAt),
            remainingTime: formatRemainingTime(currentTimestamp, startDate),
          };

          return isEqual(newValues, prev) ? prev : newValues;
        });
      };

      runUpdate();

      const interval = setInterval(runUpdate, 0.1 * MINUTE);
      return () => clearInterval(interval);
    }
  }, [values.isPast, startDate, endAt]);

  return values;
};

export const getCoachingSessionMeetingUrl = (
  coachingSessionId: Scalars['CoachingSessionUrn']['output'],
): string => `/meet/${coachingSessionId}`;
